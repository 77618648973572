.container {
  display: flex;
  flex-direction: column;
  top: 1rem;
  right: 1rem;
  position: absolute;
  gap: 8px;
}

.buttons {
  display: flex;
  flex-direction: column;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  width: 38px;
  height: 38px;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  background: #fff;
  box-sizing: border-box;
  color: rgb(192, 192, 192);
  
  &.selected {
    color: var(--primary-color);
    --tone1: var(--primary-color);
    --tone2: var(--primary-w20-color);
    --tone3: var(--primary-w40-color);
  }

  &:hover {
    background-color: rgb(243, 244, 246);
  }

  &:first-child {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }
  &:last-child {
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
  
  &.trash {
    border-radius: 0.375rem;
    background-color: rgb(254, 226, 226);
    color: rgb(239, 68, 68);

    &:hover {
      background-color: rgb(254, 202, 202);
    }
  }
}