.containerV2 {
  display: flex;
  flex-direction: row;
  border-radius: 0.85714em;
  border-width: 1px;
  border-style: solid;
  border-color: #e1e8ed;
  box-sizing: border-box;
  overflow: hidden;
  --box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  box-shadow: var(--box-shadow);
}

.imageContainerV2 {
  width: 8.81667em;
  flex-shrink: 0;
  background-color: #eee;
}

.imageContentV2 {
  position: relative;
  width: 100%;
  padding-top: var(--aspect-ratio, 100%);
}

.imageContentV2 img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.contentV2 {
  padding: 0.75em;
  box-sizing: border-box;
  text-decoration: none;
  flex-basis: 0;
  flex-grow: 1;
  overflow: hidden;
  background: #fff;

  display: flex;
  flex-direction: column;
  align-items: self-start;
}

.shortDescriptionV2 {
  max-height: 1.3em;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.6em;
  text-overflow: ellipsis;
  font-size: 1em;
  margin: 0 0 0.15em;
  font-weight: bold;
  color: #000;
}

.descriptionV2 {
  margin: 0;
  resize: none;
  text-align: left;
  padding: 0;
  margin-top: 0.32333em;
  color: #606770;
  font-size: 0.7em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hostV2 {
  font-size: 0.6em;
  text-transform: lowercase;
  color: #8899a6;
  max-height: 1.3em;
  white-space: nowrap;
  overflow: hidden;

  input {
    font-size: 0.95em;
    text-transform: uppercase;
    white-space: nowrap;
    background: none;
    line-height: 1.333em;
    text-overflow: ellipsis;
    padding-left: 0;

    color: #8899a6;
  }
}
