/*
100	Thin (Hairline)
200	Extra Light (Ultra Light)
300	Light
400	Normal (Regular)
500	Medium
600	Semi Bold (Demi Bold)
700	Bold
800	Extra Bold (Ultra Bold)
900	Black (Heavy)
950	Extra Black (Ultra Black)
*/

@font-face {
  font-family: 'tiempos';
  src: url('./tiempos/TiemposHeadline-Semibold.woff') format('woff'),
  url('./tiempos/TiemposHeadline-Semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Averta';
  font-weight: 300;
  src: url("./Averta/Averta-Light.woff2");
  font-display: swap;
}

@font-face {
  font-family: 'Averta';
  font-weight: 400;
  src: url("./Averta/Averta-Regular.woff2");
  font-display: swap;
}

@font-face {
  font-family: 'Averta';
  font-weight: 500;
  src: url("./Averta/Averta-Regular.woff2");
  font-display: swap;
}


@font-face {
  font-family: 'Averta';
  font-weight: 700;
  src: url("./Averta/Averta-Bold.woff2");
  font-display: swap;
}