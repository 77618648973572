.container {
  display: flex;
  flex-direction: row;
  gap: 6px;
}
.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.defaultText {
  padding: 3px 5px;
  font-size: 10px;
  border-radius: 4px;
  background: #bcbcbc;
  color: #fff;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap;
}

.title {
  background-color: transparent;
  font-weight: 700;
  letter-spacing: -0.025em;
  // padding-top: 20px;
  // padding-bottom: 16px;
  width: 100%;
  outline: none;
  align-self: baseline;
  flex-grow: 1;
  align-self: stretch;
  color: hsl(0, 0%, 75%);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  font-size: 16px;
  height: 100%;

  &:hover, &:focus{
    color: hsl(0, 0%, 27%);
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.home {
  margin-right: 15px;
  margin-left: 9px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 8px 6px;
  min-width: 38px;
  height: 38px;
  transition: all 130ms cubic-bezier(0.4, 0, 0.2, 1);
  color: #797575;

  input[type="checkbox"] { display: none; }

  input[type="color"] {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
  }

  svg {
    height: 75%;
    width: auto;
    display: block;
  }

  &:hover {
    color: #7a52ae;

    &.trash {
      color: rgb(239, 68, 68);
    }

    .color {
      border-radius: 50%;
      box-shadow: 0 0 0 1px white, 0 0 2px 2px #c4c4c4;
    }

    .defaultText {
      background: var(--primary-w20-color);
      color: var(--primary-w20-text-color);
    }
  }

  &.active .defaultText {
    background: var(--primary-w20-color);
    color: var(--primary-w20-text-color);
  }
}

.color {
  background-color: currentColor;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  transition: 300ms;
  box-shadow: 0 0 0 1px transparent, 0 0 2px 2px transparent;
}