.container {
  z-index: 0;
  width: 100%;
  overflow: hidden;
}

.content {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-weight: 700;
  gap: 0.5rem;
  width: 100%;
  color: rgb(170, 170, 170);
  background: #fff;
  padding: 2.5rem;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.03);
  transform: translateY(0.7rem);
  user-select: none;
  transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    transform: translateY(0);
    color: rgb(136, 136, 136);
    box-shadow: 0px 20px 24px rgba(0, 0, 0, 0.03);
  }
}

.hasNoSlides {
  padding-bottom: 32px;
}

.hasNoSlides .content {
  aspect-ratio: 16 / 9;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.03);
  border-radius: 0.75rem;
  margin-bottom: 12px;
  transform: translateY(0);

  &:hover {
    transform: translateY(0);
    color: rgb(136, 136, 136);
    box-shadow: 0px 20px 24px rgba(0, 0, 0, 0.1);
  }
}