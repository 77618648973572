.container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  max-width: 330px;
  gap: 0.5rem;
}

.option {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-right: 1rem;
  padding: 0.5rem;
  outline-style: solid;
  outline-width: 2px;
  outline-color: rgba(236, 72, 153, 0);
  transition: all 450ms cubic-bezier(0.4, 0, 0.2, 1);
  background: #fff;
  border-radius: 0.375rem;
  width: 100%;
  position: relative;
  gap: 0.75rem;

  &:hover {
    transform: scaleX(1.05);

    .key {
      background-color: var(--primary-color);
      color: #fff;
    }
    .keyText {
      color: #fff;
    }
    .keyNumber {
      margin-left: 0.2em;
    }
  }
  &.selected {
    transform: scaleX(1.1);
    background-color: var(--primary-color);

    .key {
      background-color: #fff;
      color: var(--primary-color);
    }
    .text {
      color: #fff;
    }
    .keyText {
      background: #fff;
      color: var(--primary-color);
    }
    .keyNumber {
      margin-left: 0.2em;
    }
  }
  &:hover,
  &.selected {
    .keyText {
      opacity: 1;
    }
    .key {
      box-shadow: none;
    }
  }
}

.key {
  position: relative;
  display: flex;
  align-items: center;
  transition-duration: 100ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0px 0px 0px 3px var(--primary-w90-color) inset;
  font-weight: 700;
  color: var(--primary-color);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  border-radius: 0.375rem;
  // overflow: hidden;
  font-size: 1.3rem;
}

.text {
  font-weight: 500;
  color: rgba(100, 100, 100, 1);
  font-size: 1.25rem;
  line-height: 1.75rem;
  width: 100%;
  letter-spacing: -0.03em;
}

.keyText {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  transition-duration: 100ms;
  line-height: 1.5rem;
  opacity: 0;
  overflow: hidden;
  z-index: 10;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  transform: translateX(calc(-100% + 10px));
  padding-left: 0.75rem;
  background: var(--primary-color);
  font-size: 0.75rem;
  color: var(--primary-color);
  margin-right: 0.3em;
}

.keyNumber {
  line-height: 1.5rem;
  transition: margin 200ms;
}

@media screen and (max-width: 767px) {
  .option.selected {
    transform: scaleX(1.04);
  }
}
