.container {
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  line-height: 1rem;
  transition-duration: 300ms;
  background-image: linear-gradient(96.88deg, #DDDDDD 0%, rgba(215, 215, 215, 0.12) 115.97%);
  font-weight: 900;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-left: 0.5rem;
  margin-right: 15px;
  border-radius: 0 4px 4px 0;

  &.active {
    background-image: linear-gradient(96.88deg, var(--primary-color) 0%, var(--primary-a80-color) 60%);
    margin-right: 0;
  }
}