.container {
  background: #F9F9F9;
  margin: 0;
  padding: 0;
  font-family: var(--font);
  min-height: calc(var(--vh, 1vh) * 100);
  margin-right: var(--sidepanel-width, 0);
  transition: 200ms;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.largeLoadingRow {
  width: 100px;
  height: 100px;
  margin: 10px auto;
}

.search {
  flex-grow: 1;
}

.export {
  margin: 12px 10px;
  padding: 4px 10px;
  min-height: 36px;
  font-size: 14px;
}

.emptyState{
  margin: 1em;
}