.container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex-grow: 1;
  transition: 200ms;
}

.withLeftNav {
  margin-left: var(--nav-width, 0);
}