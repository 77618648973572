.slide {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  justify-content: center;
}

.container {
  width: calc(100% - 2 * 10em);
  padding: 11rem 0 6rem;
  height: 100svh;
  display: flex;
  cursor: default;
  flex-direction: column;
  gap: 1em;
}

.bottomButtons {
  display: none;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.7));
}

.title {
  flex-grow: 0;
  flex-shrink: 1;
}

.content {
  flex-grow: 0;
  flex-shrink: 1;
  line-height: 140%;
}

.error {
  font-weight: 700;
  color: rgb(220, 38, 38);
}

.warning {
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  animation: slideFromRight 300ms;
  animation-fill-mode: both;
  padding: 1rem;
  max-width: 400px;
  width: 100%;
  border: 1px  #E04F5F solid;
  border-radius: 12px;

  .warningImage {
    --tone1: #fff;
    --tone2: #E04F5F;
    height: 15px;
    margin-right: 0.75rem;
    width:auto;
  }

  .warningText {
    margin: 0;
    color: white;
  }


  @keyframes slideFromRight {
    from {
      transform: translate(100%, 0);
    }
  }
}


.arrow {
  width: 10em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 200ms;
  height: 100%;
  position: absolute;
  color: var(--primary-w90-color);
  ;

  svg {
    transition: 0.3s;
    cursor: pointer;
    width: 30%;
  }
  
  &:hover {
    background: rgba(0, 0, 0, 0.249);

    svg {
      width: 50%;
    }
  }

  &.left {
    top: 0;
    left: 0;

    svg {
      transform: scale(1) rotate(-180deg);
    }
  }
  &.right {
    top: 0;
    right: 0;
  }
}

.ctaButton {
  display: inline-block;
  border-radius: 100em;
  padding: 0.75em 1em;
  margin-top: 2em;
  background: var(--primary-w20-color);
  color: var(--primary-w20-text-color);
  text-decoration: none;
  align-self: flex-start;
  cursor: pointer;
  transition: 200ms;
  align-self: flex-start;
  min-width: 10em;

  input {
    background: transparent;
  }

  &:hover {
    background: var(--primary-color);
    color: var(--primary-text-color);
    // transform: scale(1.1);
  }
}

@media screen and (max-width: 767px) {
  .slide {
    flex-direction: column;
  }

  .arrow {
    display: none;
  }

  .ctaButton {
    font-size: 2.2em;
    display: inline-block;
    border: 2px solid #fff;
    padding: 1.05em 1.3em;
  }

  .container {
    padding: 64px 32px;
    height: calc(100svh - 66px);
    width: 100%;
  }

  .slideNavigation {
    display: none;
  }

  .bottomButtons {
    display: flex;
    justify-content: space-between;

    .arrow {
      position: relative;
      display: flex;
      width: 66px;
      height: 66px;
      svg {
        width: 60%;
        height: 60%;
      }
    }
  }
}


