.container {
  background: #fff;
  width: 600px;
  border-radius: 0.75rem;
  font-family: var(--font);
}

.header {
  display: flex;
  padding: 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(222,222,222);
}

.trash {
  transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1);
  color: rgb(209, 213, 219);
  cursor: pointer;
  padding: 8px;
  height: 36px;
  width: 36px;
  margin-right: 8px;

  &:hover {
    color: rgb(248, 113, 113);
  }

  svg {
    height: 100%;
    width: auto;
    display: block;
  }
}
.content {
  padding: 1rem;
}