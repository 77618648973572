.container {
  position: relative;
  display: flex;
  flex-direction: column;
}

$inputVPadding: 13px;
$hPaddingOffset: 8px;
$hPadding: 11px;
$inputPaddingTop: $inputVPadding + $hPaddingOffset;
$inputPaddingBottom: $inputVPadding - $hPaddingOffset;
$iconHPadding: 33px;
$borderRadius: 5px;

.title.title {
  position: absolute;
  font-family: var(--font);
  font-size: 12px;
  color: #777;
  top: 0;
  left: 0;
  padding: 6px $hPadding 0 $hPadding;
  pointer-events: none;
  opacity: 1;
  transition: 300ms;
  transform: translateY(0);
}

.input.input {
  position: relative;
  display: block;
  border-radius: $borderRadius;
  font-family: var(--font);
  font-size: 14px;
  flex-grow: 1;
  padding-left: $hPadding;
  padding-right: $hPadding;
  padding-top: $inputPaddingTop;
  padding-bottom: $inputPaddingBottom;
  transition: 300ms;
  color: #40515E;
  width: 100%;
  line-height: 1.4em;
  min-height: calc(#{$inputVPadding + $inputVPadding} + 1.4em);
  // box-shadow: 0 0 0 1px #E6E6E6;
  border-color: #ddd !important;
  outline: none !important;
  background-color: #fff;

  &::-webkit-color-swatch-wrapper {
    padding: 0;
    border-radius: 7px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden
  }

  &::-webkit-color-swatch {
    border: none;
  }
}
.disabled .input {
  cursor: not-allowed;
}

.rightIcon, .leftIcon {
  color: #777;
}

.input:placeholder-shown {
  padding-top: $inputVPadding;
  padding-bottom: $inputVPadding;
  padding-left: $hPadding;
  padding-right: $hPadding;
}

.input[type="color"] {
  cursor: pointer;
}

.hasRightIcon .input, .hasRightIcon .title {
  padding-right: $iconHPadding;
}
.hasLeftIcon .input, .hasLeftIcon .title {
  padding-left: $iconHPadding;
}

.input:placeholder-shown + .complications .title {
  opacity: 0;
  transform: translateY(-2px);
}

.container:not(.disabled) .input:not(:placeholder-shown) {
  // box-shadow: 0 0 0 1px rgba(124, 162, 191, 0.49) !important;
  background: #F8FCFF;

  & + .complications {
    .title, .rightIcon, .leftIcon {
      color: #7CA2BF;

      &.clickable:hover {
        color: #416d8d;
      }
    }
  }
}

.error:not(.disabled) {
  .input.input {
    // box-shadow: 0 0 0 1px #BF7C7C;
    background: #FFF8F8;

    & + .complications {
      .title, .rightIcon, .leftIcon {
        color: #BF7C7C;
      }
    }
  }
}

// .container:not(.disabled) {
//   .input.input:hover {
//     box-shadow: rgba(58,151,212,.36) 0px 0px 0px 2px !important;
//   }
//   .input.input:focus {
//     box-shadow: rgba(58,151,212,.36) 0px 0px 0px 4px !important;
//   }
//   &.error .input.input:hover {
//     box-shadow: 0 0 8px 2px #BF7C7C !important;
//   }
// }

.rightIcon, .leftIcon {
  position: absolute;
  width: 18px;
  top: 7px;
  bottom: 7px;
  transition: 200ms;

  & > svg {
    display: block;
    height: 100%;
    width: 100%;
  }
  & > img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
.rightIcon {
  right: 7px;
}

.leftIcon {
  left: 7px;
}

.clickable {
  cursor: pointer;
}

.tooltip {
  background: #BF7C7C;
}

.colors {
  display: flex;
  flex-direction: row;
  margin: 4px -3px;
  flex-wrap: wrap;
}

.colorBox {
  width: 40px;
  height: 10px;
  border-radius: 3px;
  margin: 4px 3px 2px;
  max-width: calc(25% - 8px);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-size: 11px;
  cursor: pointer;
  transition: 200ms;
  border: 2px solid rgba(0,0,0,.1);
}

.colorBox:hover {
  transform: scale(1.1);
}