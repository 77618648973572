.container {
  background: #F9F9F9;
  margin: 0;
  padding: 0;
  min-height: calc(var(--vh, 1vh) * 100);
  margin-right: var(--sidepanel-width, 0);
  transition: 200ms;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  overflow: hidden;
}

.row {
  grid-column-start: 1;
  grid-column-end: -1;
  padding: 4px 0;
  position: sticky;
  bottom: 0;
  z-index: 20;
  background: #F9F9F9;
}
.loadingRow {
  grid-column-start: 1;
  grid-column-end: -1;
  height: 20px;
  padding: 4px 0;
}

.createRow {
  margin: 8px 16px;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid var(--primary-w70-color);
  color: var(--primary-color);
  text-align: center;
  transition: 200ms;
  cursor: pointer;
  display: block;
  text-decoration: none;
}
.createRow:hover {
  border: 1px solid var(--primary-color);
  background: var(--primary-color);
  color: #fff;
}

.breakButton {
  margin: 10px 0;
}

.header {
  height: 52px;
}