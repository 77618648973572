.container {
  right: 2.5rem;
  bottom: 2.5rem;
  position: absolute;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

.button {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.375rem;
  font-size: 0.75rem;
  color: rgb(136, 136, 136);
  background-color: rgb(255, 255, 255);
  line-height: 1rem;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    background-color: rgb(213, 213, 213);
    color: rgb(100, 100, 100);
  }
}