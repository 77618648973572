.content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color:  #fff;
    padding: 1em;
    overflow: hidden;
}


@media screen and (max-width: 767px) {
    .content {
      font-size: 8px !important;
      line-height: 120%;
    }
}