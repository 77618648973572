.create {
  margin: 10px 16px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  position: relative;
  border-radius: 1000em;
  background: #fff;
  outline: none;
  font-size: 16px;
  transition: 200ms;
  align-items: center;
  background: var(--primary-color);
  padding: 6px 12px;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0);
  text-decoration: none;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}