.container {
  color: rgb(192, 192, 192);
  background-color: #f9f9f9;
  scroll-behavior: smooth;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  height: 100svh;
  scroll-padding-top: 20px;
  font-family: var(--font);
  --nav-min-width: 185px;
  --nav-max-width: 140px;
  --header-height: 52px;
}

.header {
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  position: fixed;
  background-color: hsl(0, 0%, 98%);
  box-shadow: 0 2px 4px hsla(0, 0, 50%, 0);
  z-index: 10;
  padding-right: 2rem;
  transition: 300ms;
  align-self: auto;
  height: var(--header-height);
}

:global(.scrolled) .header {
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  background: hsla(0, 0%, 100%, .5);
  box-shadow: 0 4px 30px rgb(0, 0, 0, .1);
}

.hidden {
  background: repeating-linear-gradient(
    -55deg,
    hsl(0, 0%, 94%),
    hsl(0, 0%, 94%) 2px,
    hsl(0, 0%, 98%) 2px,
    hsl(0, 0%, 98%) 6px
  );
}

.content {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-top: var(--header-height);
  transition: 300ms;
  width: 80%;
}

.add {
  max-width: 1060px;
  width: 100%;
  padding: 2em 2em 0 2em;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1060px;
  width: 100%;
  margin: 0 auto;
  gap: 1em;
  padding: 1em 2em;
  font-size: 16px;
}

.spacer {
  flex-grow: 1000;
}



.leftNav {
  position: fixed;
  height: 100svh;
  top: 0;
  padding-top: calc(var(--header-height) + 1em);
  max-width: 185px;
  width: 20%;
  transition: 300ms;
  z-index: 1;
}

.sectionTitle {
  color: #b7b7b7;
  align-self: stretch;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: stretch;
}

@media screen and (min-width: 925px) {
  .content {
    margin-left: 185px;
  }
}