.container {
  display: flex;
  flex-direction: column;
  width: 50%;
}


@media screen and (max-width: 767px) {
  .container {
    font-size: 5em;
    width: 80%;
  }
}