.container {
  width: 100%;
  padding-top: var(--aspect-ratio);
  position: relative;
  overflow: hidden;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}