.navItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 1em;
  font-size: 0.875em;
  line-height: 1.25em;
  position: relative;
  color: rgb(136, 136, 136);
  transition: 200ms;
  border-radius: 0 5px 5px 0;
  padding: 12px 15px;

  &:hover {
    color: #111;
    --tone1: #111;
    --tone2: #444;
    --tone3: #888;

    .index {
      border-color: #111;
    }
  }

  &.selected {
    color: var(--primary-color);
    background: var(--primary-w80-color);

    --tone1: var(--primary-color);
    --tone2: var(--primary-w20-color);
    --tone3: var(--primary-w40-color);
  }
}

.dragger {
  position: absolute;
  top: 0;
  left: 0;
  margin: 8px 5px;
  opacity: 0;
  transform: translateX(-4px);
  transition: 200ms;
}
.navItem:hover .dragger {
  opacity: 1;
  transform: translateX(0);
}

.addNavItem {
  text-align: right;

  .container {
    width: 110px;  
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 16px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    transition: 400ms;
    cursor: pointer;
 
    &:hover {
      background: #ECECEC;
      border: 1px dashed #B9B9B9;
    }
  }

}

.text {
  user-select: none;
  letter-spacing: -0.025em;
  font-weight: 600;
  font-size: 0.875em;
  line-height: 1.25em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.index {
  margin-top: 0;
  padding-left: 1em;
  flex-grow: 1;
  font-size: 1em;
  text-align: right;
}

.icon {
  height: 22px;
  width: 22px;
  display: block;
  flex-shrink: 0;
  
  svg {
    display: block;
    height: 100%;
    width: auto;
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  width: 110px;
  aspect-ratio: 16 /9;
  overflow: hidden;
  border-radius: 6px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.03);
  font-size: 4px;
  // font-size: calc(16px/(996/110));
}

.cardContent {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  padding: 2em 3em;
  pointer-events: none;

  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3));
}

.contentTop {
  font-size: 8px;
  height: 2em;
}

.contentBottom {
  font-size: 4px;
  flex: 3 1;
  pointer-events: none;
}
