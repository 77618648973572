.multiAnswer {
  font-size: 1.125em;
  color: #62708D;
  letter-spacing: 0;
  line-height: 1.5em;
  border: 1px solid #fff;
  padding: 0.66em 3.25em;
  border-radius: 1000em;
  margin: 0.444em 0;
  transition: 300ms;
  text-align: left;
  cursor: pointer;
  outline: 0;
  background: transparent;
  position: relative;
  background: var(--primary-w98-color);
}

.multiAnswer span{
  text-align: center;
}

.multiAnswer:hover {
  transform: scale(1.1);
  border-color: var(--primary-color, #D3D4D5);
  color: var(--primary-color, #62708D);
}

.multiAnswer.selected:not(.deselecting) {
  border-color: var(--primary-color, currentColor);
  background: var(--primary-color, currentColor);
  color: var(--text-color, '#fff');
  transform: scale(1.1);

  animation-name: blinker;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-duration: var(--animation-time, 500ms);
  animation-fill-mode: both;
  animation-direction: normal;
}
.multiAnswer:active {
  border-color: var(--primary-color, currentColor);
  background: var(--primary-color, currentColor);
  color: var(--primary-text-color, '#fff');
}
.answerKeyWrap {
  position: absolute;
  top: 50%;
  left: 0;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #D3D4D5;
  background: #fff;
  transform: translate(calc(-100% + 2.6em), -50%);
}

.answerKey {
  padding-left: 0;
  display: none;
  visibility: hidden;
  opacity: 0;
  text-transform: uppercase;
  font-size: 0.75em;
  font-weight: bold;
  letter-spacing: 0.05em;
}
.answerShortcutKey {
  width: 1.375em;
  color: currentColor;
  font-size: 1em;
  /* font-weight: bold; */
}

.multiAnswer:hover .answerKey, .multiAnswer.selected:not(.deselecting) .answerKey {
  padding-left: 7px;
  display: block;
  visibility: visible;
  opacity: 1;
  
}
.multiAnswer:hover .answerKeyWrap, .multiAnswer.selected:not(.deselecting) .answerKeyWrap {
  border-color: var(--primary-color);
  background: var(--primary-w20-color);
  color: var(--primary-text-color, '#fff');
}


@media screen and (max-width: 767px) {
  .multiAnswer {
    font-size: 1em;
    padding: 0.625em 0.5em;
    text-align: center;
  }
  .answerKeyWrap {
    display: none;
  }
}

@keyframes blinker {
  25%, 75% {
    border-color: var(--primary-w90-color, currentColor);
    background: var(--primary-w90-color, currentColor);
    color: var(--primary-text-color, '#fff');
  }
  50%, 100% {
    border-color: var(--primary-color, currentColor);
    background: var(--primary-color, currentColor);
    color: var(--primary-text-color, '#fff');
  }
}