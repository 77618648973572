.container {
  min-height: 100svh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  background: white;
  color: #505050;
}

.container ::selection{
  background:  var(--secondary-color);
  color: #fff;
}

.topBar{
  background: linear-gradient(-45deg, var(--primary-color, #120D32), var(--secondary-color, #362536));
  height: 8px;
  position: absolute;
  top: 0;
  width: 100%;
}

.ourLogo{
  --tone1: var(--secondary-color);
  --tone2: var(--primary-color);
  --tone1-opacity: 0.5;
  --tone2-opacity: 0.3;
  height: 2.2em;
}

.theirLogo{
  height: 35px;
  display: block;
  width: auto;
  margin: 0 1em;
}

.container h1 {
  font-weight: bold;
  letter-spacing: -0.64px;
  line-height: 34px;
  font-family: var(--client-font);
}

.container h2 {
  letter-spacing: -0.64px;
  font-family: var(--client-font);
  margin-bottom: 0;
}

.harmonizeLogo{
  --tone1: var(--secondary-color);
  --tone2: var(--primary-color);
  --tone1-opacity: 0.5;
  --tone2-opacity: 0.3;
  --tone3: #444;
}

.content {
  max-width: 566px;
  line-height: 1.6;
}

.content a {
  color: var(--secondary-color);
  text-decoration-color: var(--secondary-w80-color);
}

.content a:hover {
  text-decoration: underline;
}

.content p{
  font-family: var(--font);
  margin: .2em 0 1em;
}

.loveIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  --tone1: #fff;
  --tone2: #fff;
  --tone1-opacity: 1;
  --tone2-opacity: 0.5;
}

.loveIcons svg {
  margin: 0 5px;
  display: block;
}

@media only screen and (max-width: 767px) {
  .container {
    flex-direction: column;
  }

  .content, .howItWorks{
    margin: 20px;
  }

}

.heart {
  animation-name: beat;
  animation-duration: 1s;
  animation-delay: 500ms;
  animation-iteration-count: infinite;
  --tone1: #D80220;
  --tone1-opacity: 1;
  --tone2-opacity: 1;
}

hr{
  border-color: rgba(255,255,255,.1);
}

@keyframes beat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
}