.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  position: relative;
}

.ctaButton {
  border-radius: 100em;
  padding: 0.75em 1em;
  background: var(--primary-w20-color);
  color: var(--primary-w20-text-color);
  text-decoration: none;
  align-self: flex-start;
  cursor: pointer;
  transition: 200ms;

  input {
    background: transparent;
  }

  &:hover {
    background: var(--primary-color);
    color: var(--primary-text-color);
    // transform: scale(1.1);
  }
}

.arrow {
  font-size: 32px;
}

.ctaLink {
  padding: 0.75em 1em;
  border-radius: 100em;
  background: #fff;

  input {
    background: transparent;
  }
}


.button {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-110%);
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  width: 38px;
  height: 38px;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  background: #fff;
  box-sizing: border-box;
  color: rgb(192, 192, 192);
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  
  &.selected {
    color: var(--primary-color);
    --tone1: var(--primary-color);
    --tone2: var(--primary-w20-color);
    --tone3: var(--primary-w40-color);
  }

  &:hover {
    background-color: rgb(243, 244, 246);
  }
  
  &.trash {
    border-radius: 0.375rem;
    background-color: rgb(254, 226, 226);
    color: rgb(239, 68, 68);

    &:hover {
      background-color: rgb(254, 202, 202);
    }
  }
}

.buttons:hover {
  .button {
    opacity: 1;
  }
} 