.text {
  text-align: center;
  color: rgb(156, 163, 175);
  max-width: 170px;
  margin-top: 1.5rem;

  b {
    font-weight: 900;
  }
}
.footer {
  margin-top: 0.5rem;
}

.hidden {
  display: none;
}

.content {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  color: rgb(209, 213, 219);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-top: 75px;
  padding-bottom: 75px;
  border-style: dashed;
  border-width: 2px;
  border-radius: 0.75rem;
  justify-content: center;
  align-items: center;
  border-bottom-color: rgb(229, 231, 235);

  * {
    pointer-events: none;
  }

  &:hover, &.dragHover {
    color: rgba(0,116,191,0.21);
    background-color: rgb(241, 248, 252);
    border-color: rgba(0,116,191,0.21);
    transform: scale(1.01);
  }
}