.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input {
  display: block;
  width: 100%;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.75rem 1rem;
  border: 2px solid rgb(243, 244, 246);
  font-family: var(--font);
  border-radius: 0.375rem;
  outline: 0;

  &:focus {
    border-color: rgba(0, 116, 191, 0.21);
    background: #fff;
    color: rgb(55, 65, 81);
  }
}

.error {
  color: rgb(248, 113, 113);
}

//poginetion
.pagination {
  margin: 10px auto;
  max-width: 1280px;
  width: 100%;
  padding: 0 4px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


.pagination button {
  display: block;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 300;
  height: 34px;
  color: #999;
  font-size: 18px;
}

.pagination button {
  padding: 0 9px;
  font-size: 12px;
  max-width: 160px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #ccc;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.pagination .defaultColor {
  border: 1px solid #ccc;
  color: #ccc;
}

.pagination button:hover {
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.pagination .currentColor {
  background: var(--primary-color);
  color: white;

  &:hover {
    color: white;
  }
}
.paginationMid {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.paginationMid > button, .paginationMid > span {
  display: inline-block;
}

.hidden {
  display: none !important;
}

.randomContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.randomContainerButton {
  padding: 10px 12px;
  font-size: 12px;
  max-width: 160px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #ccc;
  text-decoration: none;
  margin: 0 6px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background: var(--primary-color);
    color: white;
  }
}
