.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  color: #fff;
  background: #2C64BC;
  font-weight: 900;
  font-size: 1em;
  border-radius: 0.75em;
  cursor: pointer;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);

  align-self: flex-start;
  min-width: 12.5em;
  gap: 0.75em;
  padding: 0.75em 1.5em;
  line-height: 150%;

  svg {
    height: 1em;
    width: auto;
  }

  &:hover {
    background: #1f4684;
  }
}

@media screen and (max-width: 767px) {
  .container {
    font-size: 2.5em;
    letter-spacing: -0.05em;
    font-weight: 500;
    line-height: 131%;
  }
}