
.header {
  font-size: 14px;
  color: #333333;
  letter-spacing: -0.02em;
  box-sizing: border-box;
  height: var(--nav-closed-width);
  padding: 0px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: sticky;
  background: #fff;
  z-index: 10;
  top: 0;
}

.header.sticky {
  left: 0;
  z-index: 11;
  border-right: 1px solid #cecece;
}