.indicators {
  position: absolute;
  left: 50%;
  top: 8%;
  transform: translateX(-50%);
  display: flex;
  z-index: 10;
  width: 60%;
  flex-direction: row;
  max-width: 700px;
}

.indicator {
  flex-basis: 0;
  flex-grow: 1;
  height: 0.25rem;
  border-radius: 2px;
  background: var(--indicator-color, #000);
  opacity: 0.56;
  margin: 0 3px;
}

.indicatorOverlay {
  height: 100%;
  border-radius: 2px;
  background: var(--indicator-overlay-color, #fff);
  opacity: 0.95;
  width: 0;

  &.animated {
    width: 100%;
  }
  &.animating {
    animation-name: fill;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
  }
}

@keyframes fill {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .indicators{
    width: 90%;
    top: 2%;
  }
}