.container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  width: fit-content;
  border-radius: 0.375em;
  border-width: 1px;
}

.title {
  padding: 0.75em 1.5em;
  cursor: pointer;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  color: rgb(75, 85, 99);
  background: #fff;
  font-size: 1.5em;
  line-height: 2em;
  font-weight: 700;

  &:first-child {
    border-top-right-radius: 0.375em;
    border-bottom-right-radius: 0.375em;
  }

  &:last-child {
    border-top-left-radius: 0.375em;
    border-bottom-left-radius: 0.375em;
  }

  &:hover, &.selected {
    background-color: var(--primary-w20-color);
    color: #fff;


    & ~ .title {
      background-color: var(--primary-w20-color);
      color: #fff;
    }
  }
}

.littleTitle {
  padding: 0.35em 0.5em;
  cursor: pointer;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  color: rgb(75, 85, 99);
  background: #fff;
  font-size: 1em;
  line-height: 1.5em;
  font-weight: 700;

  &:first-child {
    border-top-right-radius: 0.375em;
    border-bottom-right-radius: 0.375em;
  }

  &:last-child {
    border-top-left-radius: 0.375em;
    border-bottom-left-radius: 0.375em;
  }
}

@media screen and (max-width:1500px) {
  .title {
    font-size: 1em;
  }
}

@media screen and (max-width:1200px) {
  .title {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 1000px) {
  .title {
    font-size: 0.6em;
  }
}


@media screen and (max-width: 767px) {
  .container {
    font-size: 10em;
    flex-direction: column;
    border-radius: 10px;
    gap: 1px;
    justify-content: center;
    width: 50%;
    margin: 0 auto;
    background-color: rgba(255,255,255,.85);
  }

  .title {
    padding: 8px 16px;
    text-align: center;
    &:first-child, &:last-child {
      border-radius: 0;
    }
    &:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
    &:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}
