.fullContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  min-height: calc(var(--vh, 1vh) * 100);
  position: relative;
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
  flex-grow: 1;
}


.content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  flex-grow: 1;
}

.container svg {
  width: 100%;
  height: 100%;
}

.spin {
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
  height: 100%;
  width: 100%;
}

.grid circle {
  width: 25%;
  height: 25%;
  fill: var(--primary-color);
  border-radius: 50%;
  position: absolute;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
  animation-name: blink;
  animation-fill-mode: both;
}
.grid circle:nth-child(1) {
  /* top: 0%;
  left: 0%; */
  animation-delay: 0;
}
.grid circle:nth-child(2) {
  /* top: 0%;
  left: 50%;
  transform: translate(-50%, 0); */
  animation-delay: 300ms;
}
.grid circle:nth-child(3) {
  /* top: 0%;
  right: 0%; */
  animation-delay: 800ms;
}
.grid circle:nth-child(4) {
  /* top: 50%;
  left: 0%;
  transform: translate(0, -50%); */
  animation-delay: 100ms;
}
.grid circle:nth-child(5) {
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  animation-delay: 600ms;
}
.grid circle:nth-child(6) {
  /* top: 50%;
  right: 0;
  transform: translate(0%, -50%); */
  animation-delay: 400ms;
}
.grid circle:nth-child(7) {
  /* bottom: 0%;
  left: 0; */
  animation-delay: 700ms;
}
.grid circle:nth-child(8) {
  /* bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0%); */
  animation-delay: 500ms;
}
.grid circle:nth-child(9) {
  /* bottom: 0%;
  right: 0%; */
  animation-delay: 200ms;
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}





.ripple {
  --size: 120px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: var(--size);
  height: var(--size);
  transform: translate(-50%, -50%);
  opacity: 1;
  visibility: visible;
}
.ripple div {
  position: absolute;
  border: 4px solid var(--primary-color);
  opacity: 1;
  border-radius: 50%;
  animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes ripple {
  0% {
    top: calc(var(--size) * 0.45);
    left: calc(var(--size) * 0.45);
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: calc(var(--size) * 0.9);
    height: calc(var(--size) * 0.9);
    opacity: 0;
  }
}

@keyframes blink {
 from {
   opacity: 0;
 }
 to {
   opacity: 1;
 }
}

.spinner2 {
  animation: rotate 2s linear infinite;
  z-index: 2;
}


.spinnerContainer {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
}

.path {
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

