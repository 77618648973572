.container {
  display: flex;
  flex-direction: column;
  width: 45%;
  gap: 0.5em;
  position: relative;
}


.sideActions {
  display: flex;
  align-items: center;
  gap: 3px;
  position: absolute;
  opacity: 0;
  transition: all ease-in 0.3s;
  left: 0;
  padding-right: 4px;
  transform: translateX(-100%);
}

.button {
  transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1);
  color: #fff;

  &:hover {
    color: var(--primary-color);
  }

  svg {
    height: 100%;
    width: auto;
    display: block;
  }
}

.trash {
  transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1);
  color: rgb(209, 213, 219);
  cursor: pointer;

  &:hover {
    color: rgb(248, 113, 113);
  }
}

.option {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5em;
  padding-right: 1em;
  outline: 4px solid transparent;
  background: #fff;
  border-radius: 0.375em;
  transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;

  &.isDragging .sideActions {
    opacity: 1;
  }
  
  &:hover .sideActions {
    opacity: 1;
  }
  
  &.noSideActions .sideActions {
    opacity: 0;
    pointer-events: none;
  }
  
  &.focusable:focus-within {
    outline-color: var(--primary-w20-color)
  }
}

.little .option {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.3em;
  padding-right: 0.5em;
  outline: 4px solid transparent;
  background: #fff;
  border-radius: 0.375em;
  transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
}

.box {
  display: flex;
  align-items: center;
  background-color: var(--primary-color);
  border-radius: 0.375em;
  font-weight: 700;
  padding-left: 0.75em;
  padding-right: 0.75em;
  color: var(--primary-text-color);
  aspect-ratio: 1;

  &.readOnly {
    opacity: 0.5;
  }
}

.little .box {
  display: flex;
  align-items: center;
  background-color: var(--primary-color);
  border-radius: 0.375em;
  font-weight: 700;
  padding-left: 0.45em;
  padding-right: 0.45em;
  height: 1.5em;
  width: 1.5em;
  color: var(--primary-text-color);
  aspect-ratio: 1;

  &.readOnly {
    opacity: 0.5;
  }
}

.optionText {
  font-size: 0.75em;
  line-height: 1.75em;
  padding-left: 0.75em;
  width: 100%;
  color: rgb(209, 213, 219);
}

.little .optionText {
  font-size: 0.7em;
  line-height: 1.2em;
  padding-left: 0.45em;
  width: 100%;
  font-weight: 600;
  color: rgb(209, 213, 219);
}

.optionInputText {
  padding-left: 0.75em;
  font-size: 1.25em;
  line-height: 1.75em;
  width: 100%;
  background-color: transparent;
  color: rgb(100, 100, 100);
  outline: 0;
}

.little .optionInputText {
  padding-left: 0.45em;
  font-size: 1em;
  line-height: 1.2em;
  width: 100%;
  background-color: transparent;
  color: rgb(100, 100, 100);
  outline: 0;
}