.little {
    color: rgb(192, 192, 192);
}
.little svg {
    width: 6em;
    height: 2em;
}

.subTittle {
    line-height: 1em;
}