.container {
  background: #fff;
  margin: 0;
  padding: 0;
  min-height: calc(var(--vh, 1vh) * 100);
  transition: 200ms;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  overflow: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 10px;
}

.namespaceConfig {
  padding: 10px 0;
}

.namespaceConfigContent {
  flex-grow: 1;
  max-width: 600px;
  margin: 0 auto;
}