.section {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 500ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  height: 100svh;
  z-index: 1;
  opacity: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  pointer-events: none;

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 14.432px;
  z-index: 2;
  left: 0;
  top: 0;
  background-image: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), linear-gradient(rgba(255, 255, 255, .3), rgba(255, 255, 255, .3));
}