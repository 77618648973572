.container {
  position: relative;
  display: flex;
  flex-direction: column;
}

$inputVPadding: 13px;
$hPaddingOffset: 8px;
$hPadding: 11px;
$inputPaddingTop: $inputVPadding + $hPaddingOffset;
$inputPaddingBottom: $inputVPadding - $hPaddingOffset;
$iconHPadding: 33px;
$borderRadius: 5px;

.input.input {
  position: relative;
  display: block;
  border-radius: $borderRadius;
  font-family: var(--font);
  font-size: 14px;
  flex-grow: 1;
  padding-left: $hPadding;
  padding-right: $hPadding;
  padding-top: $inputPaddingTop;
  padding-bottom: $inputPaddingBottom;
  transition: 300ms;
  color: #40515E;
  width: 100%;
  line-height: 1.4em;
  min-height: calc(#{$inputVPadding + $inputVPadding} + 1.4em);
  border-color: transparent !important;
  box-shadow: none;
  border: none;
  background-color: #fff;
  outline: 0;
  box-sizing: border-box;

  padding-top: $inputVPadding;
  padding-bottom: $inputVPadding;
  padding-left: $hPadding;
  padding-right: $hPadding;

  &::-webkit-color-swatch-wrapper {
    padding: 0;
    border-radius: 7px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden
  }

  &::-webkit-color-swatch {
    border: none;
  }
}
.disabled .input {
  cursor: not-allowed;
}

.rightIcon, .leftIcon {
  color: #777;
}

.input[type="color"] {
  cursor: pointer;
}

.hasRightIcon .input {
  padding-right: $iconHPadding;
}
.hasLeftIcon .input {
  padding-left: $iconHPadding;
}

.error:not(.disabled) {
  .input.input {
    background: #FFF8F8;

    & + .complications {
      .rightIcon, .leftIcon {
        color: #BF7C7C;
      }
    }
  }
}

.rightIcon, .leftIcon {
  position: absolute;
  width: 18px;
  top: 7px;
  bottom: 7px;
  transition: 200ms;

  & > svg {
    display: block;
    height: 100%;
    width: 100%;
  }
  & > img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
.rightIcon {
  right: 7px;
}

.leftIcon {
  left: 7px;
}

.clickable {
  cursor: pointer;
}

.tooltip {
  --tooltip-background: #BF7C7C
}

.container:not(.disabled) {
  .input:hover {
    background: rgb(255, 255, 243);
  }
  .input:focus {
    background: rgb(253, 253, 228);
  }
}