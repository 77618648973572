.cell {
  display: flex;
  font-size: 14px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  min-width: var(--nav-closed-width);
  transition: 200ms;
  padding: 10px 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover{
    cursor: pointer;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  img {
    height: calc(var(--nav-closed-width) - 20px);
    width: auto;
    display: block;
  }
  &.editable {
    position: relative;
  }
  &.pilled {
    padding: 8px 14px;
  }
  &.deleting {
    animation-name: deleting;
    animation-duration: 300ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease; 
    animation-fill-mode: both;
    animation-direction: alternate;
  }
  &.even {
    background: #ECECEC;
  }
  &.active {
    background: var(--primary-w80-color);
    --tone1-default: var(--primary-w40-color);
    --tone2-default: var(--primary-w80-color);
  }
  
  &.active.even{
    background: var(--primary-w90-color);
  }
}

a.cell {
  text-decoration: none;
  color: var(--primary-color);
  &:hover {
    text-decoration: underline;
  }
}

.sticky {
  position: sticky;
  left: 0;
  z-index: 10;
  background: #F9F9F9;
  border-right: 1px solid #d9d9d9;

  &.even {
    background: #ECECEC;
  }
}

@keyframes deleting {
  to {
    background: #ff7575;
    --tone1: #fff;
    --tone2: #fff;
    color: #fff;
  }
}