.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(var(--vh, 1vh) * 100);
  box-sizing: border-box;
  padding: 16px;
  background: var(--primary-w90-color);
}

.content {
  background: #fff;
  color: #666;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  filter: drop-shadow(0 0 25px rgba(0,0,0,0.2));
  width: 40vw;
  max-width: 100%;
  box-sizing: border-box;
  z-index: 1;
  transition: 200ms;
  position: relative;
}

.loading .input {
  opacity: 0;
}

.input {
  margin-top: 16px;
  width: 100%;
  transition: 200ms;
}
.input:first-child {
  margin-top: 0;
}

.logo {
  width: 75%;
  height: 75%;
}

.circle {
  background: #fff;
  align-self: center;
  width: 64px;
  height: 64px;
  border-radius: 1000em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0,0,0,0.1);
  transition: 200ms;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.logoContainer {
  height: 64px;
  margin-bottom: 24px;
}

.logoContainer img {
  display: block;
  height: 100%;
  width: auto;
}

.loading .circle {
  top: 50%;
}

.loading .logo {
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

.button {
  border: 1px solid var(--primary-color);
  border-radius: 7px;
  transition: 200ms;
  cursor: pointer;
  background: var(--primary-color);
  color: #fff;
  outline: none;

  font-size: 18px;
  line-height: 26px;
  color: #FFFFFF;
  letter-spacing: -0.41px;
  padding: 11px 23px;
  align-self: flex-start;

  margin: 16px auto 0 auto;
  width: 40vw;
  max-width: 100%;
  z-index: 1;
  transition: 200ms;
}
.loading .button {
  transform: translate(0, calc(-100% - 16px));
  z-index: 0;
  opacity: 0;
}
.button:not(:disabled):hover {
  background: var(--primary-color);
}

.button:disabled {
  border: 1px solid #979797;
  cursor: not-allowed;
  background: transparent;
  color: #989898;
}

.globalError {
  color: hsl(0, 100%, 44%);
}

.linkRow {
  margin-top: 12px;
  transition: 200ms;
  
}

.linkRow a {
  text-decoration: none;
  color: var(--primary-color);
}
.linkRow a:hover {
  text-decoration: underline;
}

.loading .linkRow {
  opacity: 0;
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}


.namespaceLoading {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: var(--size);
  height: var(--size);
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 10;
}

.loading .namespaceLoading {
  opacity: 1;
  visibility: visible;
}