.container {
  background-image: linear-gradient(124deg, #e2cbee 0%, #78aee9 100%);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: calc(var(--vh, 1vh) * 100);
  padding: 2.2vw;
  background: linear-gradient(160deg, #3c133c, #0a67d8bb);
  background-size: 400% 400%;
  -webkit-animation: Chameleon 5s ease infinite;
  -moz-animation: Chameleon 5s ease infinite;
  animation: Chameleon 5s ease infinite;

  h1 {
    font-weight: bold;
    text-align: center;
    margin: 17px auto 0;
    line-height: 1;
    font-size: 50px;
    letter-spacing: -0.05em;
    color: #333;
    background: -webkit-linear-gradient(45deg, hsl(278, 50%, 69%), #3d48bd 80%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  h2 {
    text-align: center;
    font-family: tiempos;
    color: #444;
  }

  p {
    color: #555;
    text-align: center;
    max-width: 30vw;
    line-height: 1.5;
    margin: 17px auto;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  background: #fff;
  border-radius: 5px;
}

.harmonizeLogo {
  align-self: center;
  height: 120px;
  width: auto;
  --tone1: #78afe98a;
  --tone2: #c3a5d3bb;
}

.spacer {
  flex-grow: 1;
}

.logoContainer {
  height: 72px;
  margin: 50px 40px;
}

.logo {
  display: block;
  height: 100%;
  width: auto;

  svg {
    display: block;
    height: 100%;
    width: auto;
  }
}

.landingImg {
  width: 700px;
  display: block;
  height: auto;
  margin: 50px auto;
}

.topHeader {
  background-image: linear-gradient(124deg, #b893cd 0%, #2588f4 100%);
  height: 7px;
  width: 100%;
  position: absolute;
  top: 0;
}

.heartBeating {
  height: 24px;
  display: inline-block;
  margin: 0 0.5em;
}

.bottomFooter {
  width: auto;
  align-self: center;
  margin-bottom: 2em;
}

@media only screen and (max-width: 767px) {
  .landingImg {
    width: 80%;
  }

  .container h1 {
    max-width: 80%;
  }

  .container {
    min-height: 100svh;
  }
}

@-webkit-keyframes Chameleon {
  0% {
    background-position: 59% 0%;
  }
  50% {
    background-position: 42% 100%;
  }
  100% {
    background-position: 59% 0%;
  }
}
@-moz-keyframes Chameleon {
  0% {
    background-position: 59% 0%;
  }
  50% {
    background-position: 42% 100%;
  }
  100% {
    background-position: 59% 0%;
  }
}
@keyframes Chameleon {
  0% {
    background-position: 59% 0%;
  }
  50% {
    background-position: 42% 100%;
  }
  100% {
    background-position: 59% 0%;
  }
}
