.button {
  border: 1px solid var(--primary-color);
  border-radius: 7px;
  transition: 200ms;
  cursor: pointer;
  background: #666;
  /* color: #fff; */
  outline: none;

  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  letter-spacing: -0.41px;
  padding: 11px 23px;
  align-self: flex-start;

  margin: 16px 0 0 0;
  width: 100%;
  max-width: 100%;
  z-index: 1;
  transition: 200ms;
  text-align: center;
  box-sizing: border-box;
}

.button:not(:disabled):hover {
  background: rgb(0, 94, 156);
}

.button:disabled {
  border: 1px solid #979797;
  cursor: not-allowed;
  background: transparent;
  color: #989898;
}

.input {
  margin-top: 10px;
  width: 100%;
}
.globalErrors {
  margin-top: 10px;
  color: #d83930;
}
