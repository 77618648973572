.container {
  background: #fff;
  width: 600px;
  border-radius: 0.75rem;
  font-family: var(--font);
}

.header {
  border-bottom: 1px solid rgb(222,222,222);
}

.content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  
}

.tile {
  flex: 1 1;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  height: 7rem;
  background-size: cover;
  position: relative;
  overflow: hidden;

  &:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    transform: scale(1.01);
  }
}


.loading {
  display: block;
  background: rgba(0, 0, 0, 0.11);
  animation: loading 3s cubic-bezier(0.46, 0.03, 0.52, 0.96) 0.5s infinite;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  position: absolute;
  font-size: 0.75rem;
  line-height: 1rem;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 6px 12px;
  color: #fff;
  background: linear-gradient(to top, rgba(18, 24, 38, 1), rgba(18, 24, 38, 0));
  height: 2rem;
}

.tileContent {
  background-image: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), linear-gradient(rgba(255, 255, 255, .3), rgba(255, 255, 255, .3));
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.colorGradient {
  background-image: linear-gradient(var(--secondary-a70-color), var(--primary-a80-color));
}

@keyframes loading {
  0% { opacity: 1; }
  50% { opacity: 0.4; }
  100% { opacity: 1; }
}
  