.highlight {
  background: var(--highlight-color);
  padding: 0 3px;
  border-radius: 4px;
  transition: 200ms;
  color: #100c18;
  box-shadow: inset 0 -1px 0 var(--color);

  &:hover {
    background: var(--hover-highlight-color);
  }
}


.searchHighlight {
  position: absolute;
  left: 0;
  color: transparent;

  em {
    font-style: normal;
    background-color: rgba(255, 255, 0, 0.60);
    color: #100c18;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 2px;
  }
}

.pRelative {
  position: relative;
}