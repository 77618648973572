.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.next, .prev, .label {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.2em 0.4em;
  text-decoration: none;
  font-size: 11px;
  color: #aaa;
  letter-spacing: 0.06em;
  cursor: not-allowed;
  

  svg {
    height: 1.5em;
    width: auto;
    display: block;
    margin: 0;
    padding: 0;
  }
}

.active {
  cursor: pointer;
  color: #333;

  &:hover {
    color: var(--secondary-color);
  }
}

.prev > span {
  padding-left: 0.25em;
}

.next > span {
  padding-right: 0.25em;
}


.label {
  display: flex;
  flex-direction: row;

  span {
    font-weight: 600;
    align-self: center;
    color: #666;
    font-size: 12px;
  }

  select {
    display: block;
    appearance: none;
    font-size: 14px;
    padding: 6px 15px 6px 6px;
    margin-left: 6px;
    color: #666;
    border: 1px solid #999;
    outline: none;
    text-align-last: center;
  }
  .select {
    position: relative;
    align-self: center;
    text-align: right;
    &:after {
      content: '';
      position: absolute;
      width: 0; 
      height: 0; 
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      
      border-top: 4px solid #999;
      top: 50%;
      right: 4px;
      transform: translate(0, -50%);
      pointer-events: none;
    }
  }
}

.create {
  margin: 10px 16px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  position: relative;
  border-radius: 1000em;
  background: #fff;
  outline: none;
  font-size: 16px;
  transition: 200ms;
  align-items: center;
  background: var(--primary-color);
  padding: 6px 12px;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0);
  text-decoration: none;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}


:global(.darkMode) {
  .next, .prev, .label {
    color: #757575;
  }

  .active {
    color: #aaa;
    &:hover {
      color: #fff;
    }
  }
}