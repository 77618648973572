.container {
  padding: 0.72727em 3.181818em;
  font-weight: 600;
  font-size: 1.375em;
  color: #FFFFFF;
  letter-spacing: -0.44px;
  text-align: center;
  background: #666;
  border-radius: 0.409090em;
  outline: 0;
  border: none;
  cursor: pointer;
  transition: 400ms;
  cursor: not-allowed;
  margin-top: 0.3125;
  align-self: flex-end;
  height: 2.772727em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.littleContainer {
  padding: 0.7em 2.181818em;
  font-weight: 600;
  font-size: 1em;
  color: #FFFFFF;
  letter-spacing: -0.44px;
  text-align: center;
  background: #666;
  border-radius: 0.25em;
  outline: 0;
  border: none;
  cursor: pointer;
  transition: 400ms;
  cursor: not-allowed;
  margin-top:0.2em;
  align-self: flex-end;
  height: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 4.5em;
  background: var(--secondary-color);
}

.container.active {
  cursor: pointer;
  background: var(--secondary-color);
  box-shadow: 0 0 0 0 transparent;
}

.container.active:hover {
  transform: scale(1.05);
  box-shadow: 0 2px 3px 0 var(--secondary-a80-color);
}

.container span {
  display: block;
}

.container svg {
  height: 0;
  --primary-color: #fff;
  display: block;
  opacity: 0;
  transition: 300ms;
}
.container.loading {
  padding: 0 0.54545em;
}
.container.loading svg {
  opacity: 1;
  height: 0.90909em;
  width: auto;
}
.container.loading span {
  opacity: 0;
  height: 0;
}

@media only screen and (max-width: 767px) {
  .container{
    font-size: 3em;
  }
}