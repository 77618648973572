.container {
  align-self: flex-start;
  transition: 200ms;
  overflow: auto;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 24px;
}

.navItem {
  margin-top: 10px;
}

.sectionContent {
  display: flex;
  flex-direction: column;
}

.home {
  margin-top: 13px;
  margin-bottom: 13px;
  color: #c0c0c0;
}

.ogMenuContainer {
  border-radius: 0px 5px 5px 0px;
  padding: 1em 1em 1em 2.5em;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:hover {
    background: var(--primary-w80-color);
    transition: 400ms;

    & > div {
      border-color: #82a2f4;
      box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
    }
  }
}
