.card {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  min-height: 35em;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  border-radius: 0.75em;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.03);

  &:hover {
    .visibleOnHover {
      opacity: 1;
    }
    .appearFromBottom {
      opacity: 1;
      transform: translateY(0);
    }
    .appearFromRight {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

.cardContent {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  padding: 5em;
  background-image: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), linear-gradient(rgba(255, 255, 255, .3), rgba(255, 255, 255, .3));
}

.contentTop {
  flex: 1 1;
}
.contentBottom {
  flex: 3 1;
}

.visibleOnHover {
  opacity: 0;
  transition: opacity 300ms;
}

.appearFromBottom {
  opacity: 0;
  transform: translateY(4px);
  transition: transform 300ms, opacity 400ms;
}

.appearFromRight {
  opacity: 0;
  transform: translateX(4px);
  transition: transform 300ms, opacity 400ms;
}

.webP {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 10px;
  margin: 10px;
  font-weight: 600;
  letter-spacing: -0.05em;
  color: rgba(255,255,255,0.2);
}

.ogSlide {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 10px;
  margin: 10px;
  font-weight: 600;
  letter-spacing: -0.05em;
  color: rgba(255,255,255,0.2);
}


.top {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.bottom {
  flex-grow: 2;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.addButtons {
  display: flex;
  flex-direction: row;
  gap: 12px;
}


.addButton {
  font-weight: 700;
  padding: 0.5rem 1rem;
  position: relative;
  border-radius: 0.75em;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  transition: 200ms;
  align-self: center;
  color: rgba(255, 255, 255, 0.5);
  border: 1px dashed hsl(0deg, 0%, 72%);
  cursor: pointer;

  &:hover {
    color: var(--primary-color);
    border-color: var(--primary-color);
    background: var(--primary-w80-color);
  }
}