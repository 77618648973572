.button {
  border: 1px solid var(--primary-color);
  border-radius: 7px;
  transition: 200ms;
  cursor: pointer;
  background: var(--primary-color);
  color: #fff;
  outline: none;

  font-size: 18px;
  line-height: 26px;
  color: #FFFFFF;
  letter-spacing: -0.41px;
  padding: 11px 23px;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  min-width: calc(20px + 46px);
  position: relative;
  box-sizing: border-box;
}
.button:not(:disabled):hover {
  box-shadow: 1px 2px 1px 0 var(--primary-w50-color);
  transform: translate(0, -2px);
}

.button:disabled {
  border: 1px solid #979797;
  cursor: not-allowed;
  background: transparent;
  color: #989898;
}

.button .loading {
  height: 20px;
  width: 20px;
  opacity: 0;
  display: block;
  transition: 2000ms;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button .active {
  opacity: 1;
}




.iconButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  // background: #fff;
  background: transparent;
  cursor: pointer;
  transition: 200ms;
  border: none;
  outline: none;
  color: #444;
  height: 100%;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  padding: 0;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  span {
    display: inline-block;
    padding: 0 1em;
  }

  svg, img {
    display: block;
    margin: 0.375em 1.375em;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    object-fit: cover;
    
    & + span {
      padding-left: 0;
      margin-left: -0.5em;
    }
  }

  img {
    width: 2.5em;
    height: 2.5em;
    margin: 0.25em 0.625em;
  }


  &:hover {
    background: var(--primary-color);
    color: var(--primary-text-color);
  }

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }

  &.active {
    color: var(--primary);
    background: #eff5f8;
  }
  &.delete:hover {
    background: #dd5c5c;
    color: #fff;
  }

  & + .iconButton {
    border-left: none;
  }
}

@media (prefers-color-scheme: dark) {
  .iconButton {
    background: #222;
    border-left-color: #333;
    border-right-color: #333;
    color: #eee;
  }
}
