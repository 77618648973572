.outerContainer {
  display: flex;
  align-items: center;
  color: #444;
}

.container {
  position: relative;
  min-width: 2.5em;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;  
}

.container svg {
  position: absolute;
  top: 0;
  left: 0;
  height: 2.5em;
  width: 2.5em;
  transition: 200ms;
  transform: scale(0.8) rotate(-90deg);
}
.circlePath {
  stroke: #E6ECF0;
  fill: none;
  stroke-width: 2;
  transition: 200ms;
}
.activeCirclePath {
  stroke: #1DA1F2;
  fill: none;
  stroke-width: 2;
  transition: 200ms;
  stroke-linecap: round;
}

.text {
  min-width: 2.14285714286em;
  text-align: center;
  font-size: 0.875em;
}
.textSmall{
  font-size: 0.875em;
}

.warning .activeCirclePath {
  stroke: #FFC654;
}

.error .activeCirclePath {
  stroke: #FF5F69;
}

.giveUp svg {
  opacity: 0;
}

.error .text, .giveUp .text {
  color: #FF5F69;
}
.warning svg, .error svg {
  transform: scale(1) rotate(-90deg);
}

  
@media only screen and (max-width: 767px) {

  .container{
    margin-right: 0;

    svg {
      font-size: 1em;
    }
  }
  
  .text{
    font-size: 1rem;
    font-weight: bold;
    padding-left: 2em;
  }
  .textSmall{
    font-size: 1rem;
    margin-left: 4px;
  }

  .activeCirclePath, .circlePath {
    stroke-width: 5;
  }


}