
.tinyContainer {
  width: 100%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.backgroundOverlay {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3));
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;
  pointer-events: none;
}

.icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;


  svg {
    display: block;
    width: 50%;
    height: 50%;
  }
}