.container {
  width: 91.666667%;
}

.placeholderTitle {
  color: rgb(255, 255, 255);
  font-weight: 700;
  font-size: 0.875em;
  line-height: 1.25em;
  padding: 0.25em 1em;
  background: rgba(31,31,31,0.8);
  border-top-left-radius: 0.375em;
  border-top-right-radius: 0.375em;
  width: fit-content;
}

.textarea {
  border-radius: 0.75em;
  height: 100%;
}

.container:has(.placeholderTitle){
  .textarea {
    border-top-left-radius: 0;
  }
}