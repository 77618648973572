.container {
  width: var(--filters-width);
  background: #fff;
  z-index: 10;
  display: flex;
  flex-direction: column;
  transition: 200ms;
  flex-shrink: 0;
}

.filtersScroll{
  padding: .7em;
}

.header {
  background-color: #464646;
  color: white;
  height: 60px;
  padding-left: 16px;
  line-height: 52px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.content {
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.section {
  display: flex;
  flex-direction: column;
  padding: 10px 4px;
  flex-shrink: 0;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border-radius: 1000em;
  padding: 6px 12px;
  cursor: pointer;

  &:hover, &.selected {
    background: var(--color);
    color: #fff;
    --tone1: var(--color);
    --tone2: #fff;
  }
}

.name {
  padding-left: 6px;
}

.icon svg {
  height: 22px;
  width: auto;
  display: block;
  transition: 200ms;
}

.sectionHeader {
  font-size: 12px;
  font-weight: 600;
  color: #808080;
  letter-spacing: 0.02em;
  line-height: 20px;
  grid-column-start: 1;
  grid-column-end: -1;
  padding: 0 12px;
}

.tags {
  margin: 0 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tag {
  background-color: rgba(0, 0, 255, 0.04);
  border-radius: 3px;
  margin: 3px;
  background: var(--primary-w90-color);
  color: #444;
  cursor: pointer;
  transition: 200ms;
  display: flex;
  flex-direction: row;

  span {
    padding: 4px 6px 4px 6px;
  }

  &.selected, &:hover {
    background: var(--primary-color);
    color: var(--primary-text-color);
  }

  &.add {
    border: 1px dashed var(--primary-color);
    background: var(--primary-w95-color);

    &:hover {
      background: var(--primary-color);
      color: var(--primary-text-color);
    }
  }
}

.trashIcon {
  cursor: pointer;
  padding: 0px 4px;
  display: flex;
  align-items: center;
  
  &:hover {
    background: rgb(229, 90, 90);

  }

  svg {
    height: 0.75em;
    width: auto;
  }
}