.container {
  font-size: 3.75rem;
  line-height: 1;
  overflow: hidden;
  width: 100%;
  font-family: var(--font);
  font-weight: 600;
}

.content {
  width: 100%;
  height: 100%;
  white-space: pre-wrap;
  color: #fff;
  letter-spacing: -0.05em;
  line-height: 140%;
}

.container2 {
  color: #fff;
  flex-grow: 1;
  font-size: 3em;
}

.container3 {
  color: #fff;
  flex-grow: 1;
  font-size: 3em;
}