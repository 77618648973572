.container, .createContainer, .deleteContainer {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  overflow: hidden;
  align-items: center;
  background: rgba(0,0,0,0.1);
  color: #606060;
  font-size: 14px;
  margin: 2px;
  line-height: 1.5em;
  padding: 0;
  border: none;
  font-weight: normal;
  text-decoration: none;
  outline: 0;
}

.container.clickable:not(:disabled) {
  cursor: pointer;
  transition: 200ms;
}
.container.clickable:not(:disabled):hover {
  background: var(--primary-color);
  color: #fff;
}

.container.status{
  background: var(--primary-color);
  color: #fff;
}

.createContainer {
  background: var(--primary-faded-color);
  color: var(--primary-color);
  transition: 200ms;
}
.createContainer:not(:disabled) {
  cursor: pointer;
}
.createContainer:not(:disabled):hover {
  background: var(--primary-color);
  color: #fff;
}
/* .createContainer:not(:disabled):hover .text {
  transform: translate(-4px, 0);
} */

.deleteContainer {
  --primary-color: rgba(255,255,255,0.1);
  border-radius: 1000em;
}
.deleteContainer .box {
  padding-left: 2px;
}
.deleteContainer:not(:disabled) {
  cursor: pointer;
}
.deleteContainer:not(:disabled):hover {
  --primary-color: #E04F5F;
  background: #E04F5F;
  color: #fff;
}
.deleteContainer:not(:disabled):hover .text {
  transform: translate(-3px, 0);
}

.box, .colorBox {
  background: var(--primary-color);
  height: calc(1.5em + 4px);
  width: calc(1.5em);
  flex-shrink: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  --tone1: #fff;
  --tone2: #fff;
  box-sizing: border-box;
}

.colorBox {
  height: calc(1.5em + 4px);
  width: calc(1.5em + 4px);
  background: var(--color, #000);
}

.box svg {
  height: 10px;
  width: auto;
  display: block;
}

.container.clickable .colorBox svg {
  opacity: 0;
  transition: 200ms;
}

.container.clickable:hover .colorBox svg {
  opacity: 1;
}

.text {
  padding: 2px 6px;
  border-radius: 6px;
  transition: transform 200ms;
}

.createContainer .text {
  background: var(--primary-w70-color);
}

.box + .text {
  border-radius: 0 6px 6px 0;
}

.deleteContainer .text {
  border-radius: 0 100em 100em 0;
}

.delete {
  height: calc(1.5em + 4px);
  width: calc(1.5em);
  margin-right: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.delete svg {
  height: 1em;
  width: auto;
}
.delete path {
  transition: 200ms;
}
.delete:hover {
  --tone1: #fff;
  --tone2: #E04F5F;
}


.pill {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
  border-radius: 7px;
  transition: 200ms;
  color: #505050;
  text-decoration: none;
}
.pill path {
  transition: 200ms;
}
.pill.clickable {
  cursor: pointer;
}
.pill span {
  margin-left: 6px;
}

.pill svg {
  height: 24px;
  width: auto;
  display: block;
}
.pill.clickable:hover {
  background: var(--primary-color);
  --tone1: var(--primary-text-color);
  --tone2: var(--primary-text-color);
  color: var(--primary-text-color);
}