.container {
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  outline: 0;
  color: rgb(136, 136, 136);
  width: 100%;
  line-height: 2em;
  height: calc(var(--width) / 10em);
  resize: none;
  height: 100%;
  font-size: 1.5em;
  padding: 1.5em;
  background: rgb(254, 252, 232);
  font-family: var(--font);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

  &:hover {
    box-shadow: 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  }
}

.textareaLittle{
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  outline: 0;
  color: rgb(136, 136, 136);
  line-height: 1.2em;
  border-radius: 0.75em;
  width: 100%;
  resize: none;
  font-size: 1em;
  padding: 1em;
  height: 4em;
  background: rgb(254, 252, 232);
  font-family: var(--font);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
 }
