.container {
  margin: 0.75em 0;
}
.little  {
  margin: 0.25em 0;
}

.title {
  font-size: 1.125em;
  color: currentColor;
  letter-spacing: -0.0002em;
}

.little .title {
  display: flex;
  align-items: center;
  font-size: 0.5em;
  color: currentColor;
  letter-spacing: -0.0002em;
  height: 2.5em;
}

.error .title {
  color: hsl(0, 100%, 44%);
}

.error .location {
  background: hsl(0, 100%, 44%);
  --tone1: #fff;
}

.inputWrap {
  display: flex;
  flex-direction: row;
}
.location {
  background: #FFFFFF;
  box-shadow: 0 0.25em 0.875em 0 rgba(0,0,0,0.21);
  border-radius: 0.5625em;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  flex-grow: 0;
  width: 4.5em;
  margin-right: 0.375em;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 200ms;
  flex-shrink: 0;
}

.location svg {
  height: 40%;
  width: 40%;
}

.location.animating svg {
  animation-name: locationBeat;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.location:hover {
  background: var(--primary-color);
  --tone1: #fff;
}

.input {
  background: #FFFFFF;
  box-shadow: 0 0.25em 0.875em 0 rgba(0,0,0,0.21);
  border-radius: 0.5625em;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  flex-grow: 1;
}

.little .input {
  border-radius: 0.2625em;
}

.input input {
  font-size: 1.3em;
  padding: 0.57142857142em;
  border-radius: 0.32142857142em;
  flex-grow: 1;
  border: 0.1875em solid transparent;
  transition: 0.3s all;
  font-family: var(--font);
  letter-spacing: -0.02em;
  line-height: 1.5em;
  padding: 0.42857142857em 0.57142857142em;
}

.little .input input {
  font-size: 0.5em;
  line-height: 1.1em;
}


.input input:focus::placeholder{
  color: rgb(214, 205, 186) !important;
}

.input input::-webkit-input-placeholder { /* Edge */
  color: rgb(184, 191, 233);
}

.input input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgb(184, 191, 233);
}

.input input::placeholder {
  color: rgb(184, 191, 233);
}

.input input:focus{
  background-color: #ffffdd;
  outline: none; 
  border-color: rgba(0, 128, 0, 0.7);
  border-color: var(--primary-w30-color);
}


@keyframes locationBeat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.6);
  }
}


@media only screen and (max-width: 767px) {
  .title {
    font-size: 2em;
    margin-bottom: 10px;
  }

  .input input {
    font-size: 2.3em;
    padding: 0.6em;
  }

  .location {
    width: 3.75em;
  }
}

/* @media screen and (max-width:1375px) {
  .title {
    font-size: 1em;
  }

  .input input {
    font-size: 1.3em;
    padding: 0.6em;
    height: 2em;
  }
} */