.container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  width: var(--nav-closed-width);
  transition: 200ms;
  overflow: hidden;
  z-index: 100;
  background: #f6f5f8;

  &.opened {
    width: var(--nav-opened-width);
  }
  
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  overflow: auto;
}

.logoRow {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  text-decoration: none;
  background: #fff;
  color: #100c18;
  transition: 200ms;
  height: 60px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: tiempos;
  --row-icon-size: var(--nav-closed-width);
}

.row {
  --row-icon-size: var(--nav-closed-width);
  display: flex;
  flex-direction: row;
  position: relative;
  height: var(--row-icon-size);
  align-items: center;
  flex-shrink: 0;
  text-decoration: none;
  color: #847c7c;
  transition: 200ms;
  overflow: hidden;

  &.clickable {
    cursor: pointer;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0;
    background: var(--primary-color);
    transition: 200ms;
  }
}

.row.active, a.row:hover, .row.clickable:hover {
  background: var(--primary-w90-color);
  --tone1: var(--primary-color);
  --tone2: var(--primary-color);
  --tone2-opacity: 0.2;
  color: var(--primary-color);
}
.row.active {
  font-weight: 500;
}

.row.active:after {
  width: 6px;
}

.rowIcon, .logo {
  flex-shrink: 0;
  height: var(--row-icon-size);
  width: var(--row-icon-size);
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  transition: 200ms;
}
.logo {
  width: auto;
  min-width: var(--row-icon-size);
}
.rowIcon svg {
  display: block;
  height: 20px;
  width: auto;
}

.rowBrandIcon {
  opacity: 0.5;
}
.rowBrandIcon:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #d8d8d8;
  z-index: 1;
  border-radius: 50%;
  mix-blend-mode: color;
  opacity: 1;
}

.opened .row *:first-child {
  margin-left: 6px;
}

.row.active .rowBrandIcon {
  opacity: 1;
}
.row.active .rowBrandIcon:after {
  opacity: 0;
}

.logo svg, .logo img {
  display: block;
  height: 60%;
  width: auto;
}

.rowText {
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;

  &:last-child {
    padding-right: 8px;
  }
}

.opened .rowText {
  opacity: 1;
  width: auto;
}


.togglePanel {
  position: absolute;
  bottom: 0;
  right: 0;
  width: var(--nav-closed-width);
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: rgba(160,160,160,1);
  cursor: pointer;
  transition: 200ms;

  &:after {
    content: '';
    height: 1px;
    left: 4px;
    right: 4px;
    top: 0;
    background: linear-gradient(90deg, rgba(208, 208, 208, 0), rgb(208, 208, 208), rgba(208, 208, 208, 0));
    position: absolute;
    transition: 200ms;
  }

  svg {
    height: 16px;
  }

  &:hover {
    background: var(--primary-w90-color);
    --tone1: var(--primary-color);
    --tone2: var(--primary-color);
    --tone2-opacity: 0.2;
  }
}

.opened .togglePanel {
  position: absolute;
  bottom: 0;
  right: 0;
  height: var(--nav-closed-width);
  width: 32px;
}
.opened .togglePanel:after {
  opacity: 0;
}

.opened .togglePanel svg {
  transform: rotate(180deg);
}

.footer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  padding-bottom: 32px;
  transition: 200ms;
  background: #f6f5f8;

  &:before {
    content: '';
    height: 1px;
    top: 0;
    left: 4px;
    right: 4px;
    background: linear-gradient(90deg, rgba(208, 208, 208, 0), rgb(208, 208, 208), rgba(208, 208, 208, 0));
    position: absolute;
    transition: 200ms;
    opacity: 0;
  }
}

.opened .footer {
  padding-bottom: 0;

  &:last-child {
    padding-right: 32px;
  }
  &:before {
    opacity: 1;
  }
}

.feedback {
  z-index: 1;
}

.opened .feedback {
  --row-icon-size: calc(var(--nav-closed-width) * 0.65);
  background: var(--primary-w20-color);
  color: #fff;
  margin: 0 10px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  --tone2: #fff;
  --tone2-opacity: 1;
  transform: translate(0, 2px);

  &:hover {
    transform: translate(0, 0);
    --tone2: #fff;
    --tone2-opacity: 1;
    background: var(--primary-color);
    color: #fff;
  }
}

.feedback + .footer {
  z-index: 2;
}