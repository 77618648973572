.tableContainer {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex-grow: 1;
  flex-basis: 0;
  overflow: hidden;
}

.table {
  display: grid;
  grid-template-columns: var(--table-template-columns);
  grid-auto-rows: max-content;
  flex-grow: 1;
  overflow: auto;
}