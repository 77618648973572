.container {
  --border-radius: 10px;
  --box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.content {
  border-left: 1px solid #e9ecf1;
  border-right: 1px solid #e9ecf1;
  border-bottom: 1px solid #e9ecf1;
  background: #fff;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.625em 0.75em;
}

.host {
  color: #8899a6;
  font-size: 0.7em;
  line-height: 1.333em;
  width: 100%;
  text-overflow: ellipsis;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;

  input {
    font-size: 0.95em;
    text-transform: uppercase;
    white-space: nowrap;
    background: none;
    text-overflow: ellipsis;
    line-height: 1.333em;
    margin-left: none;
    color: #8899a6;
    padding-left: 0;
  }
}

.shortDescription {
  color: #1d2129;
  font-size: 0.7em;
  line-height: 1.25em;
  font-weight: 600;
  margin-top: 3px;
  padding-top: 2px;
  letter-spacing: -0.02em;
  background: none;
}

.description {
  font-size: 0.6em;
  background: none;
  line-height: 1.42857143em;
  margin-top: 3px;
  color: #606770;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.image {
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  position: relative;

  .input {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    background: none;
    text-align: center;
    font-size: 1.4em;
    color: white;
    z-index: 13;
  }

  &:hover {
    .appearFromTop {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
img {
  width: 100%;
  height: 100%;
}

.appearFromTop {
  z-index: 2;
  position: absolute;
  opacity: 0;
  transform: translateY(4px);
  transition: transform 300ms, opacity 400ms;
  right: 3%;
  top: 3%;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.375rem;
  font-size: 0.75rem;
  color: rgb(136, 136, 136);
  background-color: rgb(255, 255, 255);
  line-height: 1rem;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    background-color: rgb(213, 213, 213);
    color: rgb(100, 100, 100);
  }
}
