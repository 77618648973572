.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 10;
  flex-grow: 1;
  background: #f9f9f9;
}

.content {
  padding: 10px 16px 100px;
  min-height: 100%;
  display: flex;
  flex-direction: column; 
}

.input {
  margin: 18px 0;
}
.input:first-child {
  margin-top: 5px;
}