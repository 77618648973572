.container {
  padding: 10px;
  position: sticky;
  top: 0;
  z-index: 10;
  background: var(--primary-w90-color);
}

.tag {
  padding: 4px 6px 4px 6px;
  border-radius: 3px;
  margin-top: 6px;
  margin-right: 3px;
  display: inline-block;
  font-size: 15px;
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  --tone1: var(--primary-text-color);
  cursor: pointer;
  transition: 100ms padding ease-in;
  position: relative;
}

.tag:hover {
  padding: 4px 20px 4px 6px;
}

.tag svg {
  position: absolute;
  top: 50%;
  right: 6px;
  width: 0;
  height: 10px;
  display: block;
  transform: translateY(-50%);
  transition: 100ms ease-in;
}

.tag:hover svg {
  width: 10px;
}