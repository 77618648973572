.container {
  font-size: 3.75em;
  line-height: 1;
  flex: 1 1;
  color: #ffff;
  position: relative;
}

.textarea {
  font-weight: 600;
  line-height: 1.25em;
  letter-spacing: -0.05em;
  font-family: var(--font);
  resize: none;
  background: transparent;
  width: 100%;
  height: 100%;
  color: #fff;
  outline-width: 0px;
}

.delete {
   font-size: 16px;
   color: red;
}

.button {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-110%);
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  width: 38px;
  height: 38px;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  background: #fff;
  box-sizing: border-box;
  color: rgb(192, 192, 192);
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  
  &.selected {
    color: var(--primary-color);
    --tone1: var(--primary-color);
    --tone2: var(--primary-w20-color);
    --tone3: var(--primary-w40-color);
  }

  &:hover {
    background-color: rgb(243, 244, 246);
  }
  
  &.trash {
    border-radius: 0.375rem;
    background-color: rgb(254, 226, 226);
    color: rgb(239, 68, 68);

    &:hover {
      background-color: rgb(254, 202, 202);
    }
  }
}

.container:hover {
  .button {
    opacity: 1;
  }
} 

@media screen and (max-width: 1375px) {
  .container {
    font-size: 3em;
  }  
}