.container {
  background: #F9F9F9;
  margin: 0;
  padding: 0;
  font-family: var(--font);
  min-height: calc(var(--vh, 1vh) * 100);
  margin-right: var(--sidepanel-width);
  transition: 200ms;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  overflow: hidden;
}


.largeLoadingRow {
  grid-column-start: 1;
  grid-column-end: -1;
  height: 100px;
  margin: 10px;
  padding: 4px 0;
}

.emptyState {
  color: #a0a0a0;
  grid-column-start: 1;
  grid-column-end: -1;
  margin: 0 auto;
  padding-top: 3em;
  letter-spacing: -0.02em;
  text-align: center;
  line-height: 1.5em;
}

.emptyStateAdd {
  margin: 11px 4px;
  display: inline-flex;
  flex-direction: row;
  flex-shrink: 0;
  position: relative;
  border-radius: 1000em;
  outline: none;
  font-size: 14px;
  transition: 200ms;
  align-items: center;
  background: var(--primary-color);
  padding: 10px 13px;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0);
  border: none;
  text-decoration: none;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}

.image.image {
  height: calc(var(--nav-closed-width) - 20px);
  width: calc((var(--nav-closed-width) - 20px)*4/3);
  margin-left: 2px;
  border-radius: 4px;
  overflow: hidden;
}
.image.image:first-child {
  margin-left: 0;
}

.header {
  font-size: 1em;
  font-weight: 700;
  letter-spacing: -0.02rem;
  color: #444;
  padding: 5px 6px;
  margin: 0 0 0 10px;
  align-self: center;
  white-space: nowrap;
  display: flex;
}