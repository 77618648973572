
.feedback {
  position: relative;
  border-radius: 6px;
  box-shadow: 0 1px 6px rgba(0,0,0,0.07);
  background: #fff;
  z-index: 1;
  margin: 6px 10px;
  transition: 400ms;
}

.content {
  border-radius: 4px;
  overflow: hidden;
  padding: 10px 0;
}

.actionsContainer {
  display: flex;
  justify-content: space-between;
  
}

.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px 13px;
  gap: 3px;
}

.tag {
  padding: 4px 6px 4px 6px;
  background-color: rgba(0, 0, 255, 0.04);
  border-radius: 3px;
  display: inline-block;
  background: var(--primary-w90-color);
  color: #444;
  cursor: pointer;
  font-size: 10px;
}

.deleteButton {
  color: #c90000;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  outline: none;
  transition: 350ms;
  padding: 0 7px 7px 0;
  margin-right: 4px;
  text-decoration: none;

  &:hover {
    font-weight: bold;
  }
}

.linkedin {
  display: flex;
  flex-direction: row;
  padding: 12px 6px 4px 6px;
  gap: 5px;
  margin-top: 8px;

  border-top: 1px solid rgb(245, 245, 245);

  img {
    height: 22px;
    width: 22px;
    object-fit: cover;
    border-radius: 50%;
  }
}