.container {
  background: #fefefe;
  box-shadow: 0 4px 14px 0 rgba(0,0,0,0.21);
  border-radius: 1.0625em;
  display: flex;
  flex-direction: column;
  position: relative;
}

.scrollable {
  margin-bottom: 4.5625em;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #fefefe;
  border-radius: 1.0625em 1.0625em 0 0;
}

.scrollable:focus-within {
  background-color: #ffffde;
}

.textarea, .highlights {
  padding: 0.71428571428em 0.85714285714em;
  border: none;
  resize: none;
  outline: 0;
  flex-grow: 1;
  flex-shrink: 0;
  font-weight: 400;
  font-size: 1.5em;
  color: #646464;
  letter-spacing: -0.02em;
  line-height: 1.5em;
  white-space: pre-wrap;
  word-wrap: break-word;
  box-sizing: border-box;
  overflow: hidden;
  text-rendering: geometricPrecision;
  border-radius: 1.0625em 1.0625em 0 0;
  min-height: 200px;
  font-family: var(--client);
}
.textarea {
  background: transparent;
  z-index: 10;
}

.textarea:focus::placeholder{
  color: rgb(214, 205, 186) !important;
}

textarea::-webkit-input-placeholder { /* Edge */
  color: rgb(184, 191, 233);
}

textarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgb(184, 191, 233);
}

textarea::placeholder {
  color: rgb(184, 191, 233);
}

.textarea mark {
  background: #FF5F69;
  color: #fff;
}
.highlights {
  color: transparent;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.highlights mark {
  background: #FF5F69;
  color: #fff;
  border-radius: 2px;
}

.tipBoxText{
  font-size: 1em;
}

.footer, .headsUpfooter {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #F0F0F0;
  padding: 0.75em 1.0625em;
  align-items: center;
  justify-content: space-between;
  transition: 400ms;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
.headsUpfooter {
  border-top: none;
  text-align: center;
  color: #666;
  padding: 1.25em 1.0625em;
  height: 4.5625em;
  box-sizing: border-box;
  transform: translate(0, 0.625em);
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container:focus-within .headsUpfooter:not(.hidden) {
  transform: translate(0, 0);
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}
.hidden {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transform: translate(0, 0.625em);
}

.submit {
  padding: 0 3.5em;
  font-weight: 500;
  font-size: 1.25em;
  color: #FFFFFF;
  letter-spacing: -0.44px;
  text-align: center;
  background: #666;
  border-radius: 0.45em;
  outline: 0;
  border: none;
  cursor: pointer;
  transition: 400ms;
  cursor: not-allowed;
  height: 2.4em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.submit.active {
  cursor: pointer;
  background: var(--primary-color);
}

.submit.active:hover {
  transform: scale(1.05);
}

.submit span {
  display: block;
}

.submit svg {
  height: 0px;
  --primary-color: #fff;
  display: block;
  opacity: 0;
  transition: 300ms;
}
.submit.loading {
  padding: 0 12px;
}
.submit.loading svg {
  opacity: 1;
  height: 1.25em;
  width: auto;
}
.submit.loading span {
  opacity: 0;
  height: 0;
}


@media only screen and (max-width: 767px) {
  .textarea, .highlights {
    font-size: 18px;
    padding: 12px 16px;
    min-height: calc(3em + 24px);
  }
  .submit {
    padding: 12px;
    height: unset;
    font-size: 1.4em;
    font-weight: bold;
  }
  .footer, .headsUpfooter {
    padding: 4px 17px;
  }
  .headsUpfooter {
    height: 54px;
  }
  .scrollable {
    margin-bottom: 54px;
  }

  .tipBoxText{
    font-size: 14px;
    line-height: 140%;
  }

  .textarea, .highlights {
    min-height:  200px;
    font-size: 22px;
  }
}