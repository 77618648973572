.button {
  border: 1px solid var(--primary-color);
  border-radius: 7px;
  transition: 200ms;
  cursor: pointer;
  background: var(--primary-color);
  color: #fff;
  outline: none;

  font-size: 18px;
  line-height: 26px;
  color: #FFFFFF;
  letter-spacing: -0.41px;
  padding: 11px 23px;
  align-self: flex-start;

  margin: 16px 0 0 0;
  width: 100%;
  max-width: 100%;
  z-index: 1;
  transition: 200ms;
  text-align: center;
  box-sizing: border-box;
}
.button:not(:disabled):hover {
  background: rgb(0, 94, 156);
}

.button:disabled {
  border: 1px solid #979797;
  cursor: not-allowed;
  background: transparent;
  color: #989898;
}

.input {
  margin-top: 10px;
  width: 100%;
}

.values {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0;
}

.header { 
  font-size: 13px;
  color: #595959;
  letter-spacing: 0.72px;
  font-weight: 500;
  margin: 0 0 4px 0;
}