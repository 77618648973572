.container {
  min-width: 110px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 9px;
  padding: 1em;
  gap: 1.2em;
  cursor: pointer;
  font-size: 0.5em;
  width: 100%;

  &:hover {
    border-color: #82a2f4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .contentLeft {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.2em;
    flex-grow: 1;
    overflow: hidden;
  }

  .contentRight {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 0.2em;
    flex-shrink: 0;

    .icon {
      height: 1.5em;
    }
  }
}

.buttonContainer {
  width: 70px;
  font-size: 0.2em;
  line-height: 4.25em;
}

.containerPreviewCardMenu {
  flex-basis: 0;
  flex-grow: 1;
  font-size: 0.3em;
}
