.container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  background-color: #4c51a50b;
  transition: 200ms;

  h2 {
    font-size: 1em;
    font-weight: 700;
    color: #444;
    padding: 5px 6px;
    margin: 0;
    align-self: center;
    white-space: nowrap;
    display: flex;
  }
}

.add {
  margin: 2px 3px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  position: relative;
  border-radius: 1000em;
  background: #fff;
  outline: none;
  font-size: 16px;
  transition: 200ms;
  align-items: center;
  background: var(--primary-color);
  padding: 0 12px;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0);

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}



@media screen and (max-width: 768px) {
  .container{
    overflow: scroll;
  }
}