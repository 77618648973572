.answer {
  display: flex;
  flex-direction: row;
  padding: 0 10px;

  & + .answer {
    border-top: 1px solid rgb(245, 245, 245);
    padding-top: 8px;
    margin-top: 8px;
    
  }
}

.icon {
  height: 0.75em;
  width: auto;
  flex-shrink: 0;
  margin-top: 0.2em;
  color: #888;
}

.content {
  display: flex;
  flex-direction: column;
}

.title {
  color: #999;
  font-size: 12px;
  line-height: 1.6;
  padding: 0 8px 3px 8px;
  letter-spacing: 0.05em;
  font-weight: 500;
}
.answerTitle {
  padding: 0 13px 3px 13px;
}

.text {
  color: rgb(16, 12, 24);
  font-size: 16px;
  line-height: 1.5;
  position: relative;
  transition: 200ms;
  padding: 0 8px 15px 8px;
}
