.container {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: var(--sidepanel-width);
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  z-index: 200;
  animation: slideFromRight 200ms;
  animation-fill-mode: both;
  background: #fff;
}

.saving {
  position: absolute;
  top: 0;
  right: var(--sidepanel-width);
  display: block;
  transform: translate(50%, -50%) rotate(-90deg) translate(-50%, 50%);
  background: var(--primary-color);
  color: var(--primary-text-color);
  padding: 6px 16px;
  font-size: 16px;
  z-index: 1;
  border-radius: 10px 10px 0 0;
  opacity: 0;
  transition: 200ms;
}
.saving.active {
  opacity: 1;
  transform: translate(50%, -50%) rotate(-90deg) translate(-50%, -50%);
}

.content {
  z-index: 2;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: #fff;
}

@keyframes slideFromRight {
  from {
    transform: translate(100%, 0);
  }
}

@media screen and (max-width: 767px) {
  .container{
    left: 0;
    width: var(--sidepanel-width - 10);
  }
}