.container {
  max-width: 100vw;
  border-radius: 0.85714em;
  border-width: 1px;
  border-style: solid;
  border-color: #e1e8ed;
  box-sizing: border-box;
  overflow: hidden;
  --box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  box-shadow: var(--box-shadow);
  border: none;
  box-shadow: none;
  font-size: 1.4em;
  height: 100%;
  overflow-y: auto;
}

.navigationBlock {
  display: flex;
  justify-content: flex-end;
}

.functionalityBlock {
  display: flex;
  flex-direction: column;
  font-size: 1em;
  align-items: center;
  justify-content: center;
  gap: 2em;
  margin-bottom: 2em;

  .column{
    gap: 1em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .header{
    font-size: 16px;
    letter-spacing: -0.03em;
    color: #606060;
  }

  .previews {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 2em;
  }
}

.imageContainer {
  width: 8.81667em;
  flex-shrink: 0;
}

.imageContent {
  position: relative;
  width: 100%;
  padding-top: var(--aspect-ratio, 100%);
}

.imageContent img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.content {
  padding: 0.75em;
  box-sizing: border-box;
  text-decoration: none;
  flex-basis: 0;
  flex-grow: 1;
  overflow: hidden;
  background: #fff;
}

.title {
  max-height: 1.3em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1em;
  margin: 0 0 0.15em;
  font-weight: bold;
  color: #000;
}

.description {
  margin: 0;
  padding: 0;
  margin-top: 0.32333em;
  max-height: 3.9em;
  font-size: 0.875em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.host {
  font-size: 0.875em;
  text-transform: lowercase;
  color: #8899a6;
  max-height: 1.3em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.classname {
  font-size: 5em;
}

.popupClassName {
  // max-width: 100%;
  width: 80%;
}

.xmark {
  cursor: pointer;
  color: #c9c9c9;
  transition: 300ms;

  &:hover {
    color: #a3a3a3;
    scale: 1.1;
  }
}

.containerPreviewCard {
  min-width: 20em;
}
