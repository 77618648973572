.heart {
  animation-name: beat;
  animation-duration: 1s;
  animation-delay: 500ms;
  animation-iteration-count: infinite;
  --tone1: #D80220;
  transform-origin: 0% 100%;
}

.heart svg {
  height: 100%;
  width: auto;
  display: block;
}

@keyframes beat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
}