.container {
  font-family: var(--client-font);
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(var(--secondary-a70-color), var(--primary-a80-color));

  h1 {
    font-size: 14em;
    font-weight: 500;
    line-height: 1em;
    color: var(--primary-text-color);
  }
}

p.content {
  font-size: 24px;
  margin: 10px;
  width: 50vw;
  text-align: center;
  color: var(--primary-text-color);
  margin-bottom: 2em;

  a {
    color: var(--primary-text-color);
    text-decoration-color: rgba(255, 255, 255, 0.2);
  }
}

.button {
  background: var(--primary-b50-color);
  color: #fff;
  padding: 1em;
  border-radius: 6px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;
  outline: none;
  text-decoration: none;

  &:hover {
    background: var(--primary-b20-color);

    .arrow {
      transform: translate(4px);
    }
  }
}

.arrow {
  display: inline;
  margin-left: 10px;
  height: 20px;
  width: auto;
  --tone1: #fff;
  vertical-align: middle;
  transition: 0.3s;
}

.heartBeating {
  height: 80px;
  width: auto;
  stroke: var(--primary-w20-color);
}
