:root {
  --font: "Averta", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  --client-font: var(--font);
}

body {
  margin: 0;
  font-family: var(--font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --zIndexSidebar: 2000;
  --zIndexPopup: 10000;
  --zIndexSticky: 1000;

  --sidepanel-width-opened: 40vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}


html, body {
  padding: 0;
  margin: 0;
  overscroll-behavior: none;
  font-family: var(--font);
  scroll-behavior: smooth;
}

input, textarea, button {
  font-family: var(--font);
  border: none;
  box-shadow: none;
  outline: none;
}
button {
  background: transparent;
}

a {
  text-decoration: none;
}


body {
  margin: 0;
  padding: 0;
  /* height: 100%; */
  font-family: var(--font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --nav-closed-width: 52px;
  --nav-opened-width: 310px;
  /* --nav-width: var(--nav-closed-width); */


  --tone1-default: rgba(160,160,160,1);
  --tone2-default: rgba(160,160,160,0.4);
  --tone3-default: rgba(160,160,160,0.2);
  --tone1-opacity-default: 1;
  --tone2-opacity-default: 1;
  --tone3-opacity-default: 1;
}

.selected {
  color: #245981;
  --tone1: #245981;
  --tone2: #1B5179;
  --tone3: #194C74;
}

input.no-spin::-webkit-outer-spin-button,
input.no-spin::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number].no-spin {
  -moz-appearance:textfield;
}