.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
  border-bottom: 1px solid #eee;

  h6 {
    margin: 0;
    font-weight: 600;
    font-size: 15px;
    line-height: 1.5em;
    color: #373737;
    letter-spacing: -0.23px;
    padding: 18px 10px;
    flex-shrink: 1;
  }
}

.closeIcon {
  color: #acacac;
  cursor: pointer;
  height: 26px;
  width: auto;
  padding: 4px;
  flex-shrink: 0;

  &:hover {
    color: gray;
  }

  svg {
    height: 100%;
    width: auto;
    display: block;
  }
}


@media (prefers-color-scheme: dark) {
  .header {
    background: #222;
    border-bottom-color: #333;

    h6 {
      color: #fff;
    }
  }
}
