.container {
  font-weight: 700;
  padding: 0.5rem 1rem;
  position: relative;
  border-radius: 0.75em;
  color: hsl(0, 0%, 53%);
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  transition: 200ms;
  align-self: center;
  border: 1px solid transparent;

  // cursor: text;
  &:hover, &:focus-within {
    transform: scale(1.05);
    color: hsl(0, 0%, 33%);

    .icon {
      animation: shake .5s cubic-bezier(.36,.07,.19,.97) both;
    }
  }

  input {
    flex-grow: 0;
    min-width: 1ch;
    width: auto;
    flex-basis: auto;
    outline: none;
    font-family: var(--font);
    font-weight: 700;
    font-size: 16px;
    padding: 0;
    color: currentColor;

    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
  }
}

.empty {
  background: transparent;
  color: hsl(0, 0%, 72%);
  border: 1px dashed hsl(0, 0%, 72%);
}

.icon {
  transition: 200ms;
  margin-right: 4px;
}
.seconds {
  pointer-events: none;
}


@keyframes shake {
  0% { transform: rotate(0); }
  15% { transform: rotate(5deg); }
  30% { transform: rotate(-5deg); }
  45% { transform: rotate(4deg); }
  60% { transform: rotate(-4deg); }
  75% { transform: rotate(2deg); }
  85% { transform: rotate(-2deg); }
  92% { transform: rotate(1deg); }
  100% { transform: rotate(0); }
}