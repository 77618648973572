.container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}

.title {
  color: #555555;
  font-size: 14px;
  margin: 0 0 6px 0;
  padding: 0;
  min-width: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: 200ms;
  letter-spacing: 0.02rem;
}

.label {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  margin: 0;
  position: relative;
  cursor: pointer;
}
.input {
  background: #F8F8F8;
  border: 1px solid transparent;
  font-family: var(--font);
  border-radius: 5px;
  color: #4B4B4B;
  letter-spacing: -0.41px;
  padding: 8px 11px;
  margin: 0;
  min-width: 0;
  width: 0;
  flex-grow: 1;
  transition: 200ms;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 1.5em;
  font-size: 1em;
  text-align: left;
}

.input::placeholder, .input.placeholder {
  color: #bbb;
}

.container:not(.disabled) .input:hover {
  box-shadow: 0 0 0 1px var(--primary-w40-color), 0 0 12px 0 var(--primary-w80-color);
  border-color: var(--primary-w40-color);
  background: var(--primary-w95-color);
}

.draggingOver:not(.disabled) .input {
  box-shadow: 0 0 0 1px var(--primary-w40-color), 0 0 12px 0 var(--primary-w80-color);
  border-color: var(--primary-w40-color);
  background: var(--primary-w95-color);
}

.disabled .input {
  cursor: not-allowed;
}

.input:focus, .input.focus, .input:focus-within {
  outline: 0;
  box-shadow: 0 0 0 1px var(--primary-w50-color);
  border-color: var(--primary-w40-color);
  background: var(--primary-w95-color);
}


.label.color .input {
  padding-left: 43px;
  padding-right: 32px;
}

.label.hasDelete .input {
  padding-right: 32px;
}

.input[type=file] {
  display: none;
}

.input[type=number] {
  -moz-appearance: textfield;
}
.input[type=number]::-webkit-inner-spin-button, .input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.floatTitleContainer .title {
  position: absolute;
  top: 6px;
  left: 12px;
  right: 0;
  font-size: 12px;
  color: var(--primary-b30-color);
  opacity: 1;
}

.floatTitleContainer .input:placeholder-shown + .title {
  opacity: 0;
}

.floatTitleContainer .input:not(:placeholder-shown) {
  padding-top: 22px;
}

.imgFile {
  display: block;
  max-width: 100%;
  height: auto;
}

.error .input {
  background: rgba(224, 79, 95, 0.10);
  border: 1px solid hsl(0, 47%, 56%);
  box-shadow: 0 0 0 3px hsla(0, 100%, 39%, 0.5);
  color: #d66a6a;
}

.error .title {
  color: #d83930;
  margin-bottom: 10px;
}

.delete {
  position: absolute;
  right: 9px;
  top: 50%;
  fill: #C4C4C4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0;
  padding: 4px;
  transition: 200ms;
  transform: translate(0, -50%)
}
.delete:hover {
  fill: var(--primary-color);
}

.chevron {
  position: absolute;
  right: 13px;
  top: 0;
  height: 100%;
  fill: #C4C4C4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.colorPicker {
  position: absolute;
  top: 100%;
  z-index: 400;
}

.colors {
  display: flex;
  flex-direction: row;
  margin: 4px -3px;
  flex-wrap: wrap;
}

.colorBox {
  width: 40px;
  height: 10px;
  border-radius: 3px;
  margin: 4px 3px 2px;
  max-width: calc(25% - 8px);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-size: 11px;
  cursor: pointer;
  transition: 200ms;
  border: 2px solid rgba(0,0,0,.1);
}

.colorBox:hover {
  transform: scale(1.1);
}

.values {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -2px;
  margin-bottom: 8px;
}


input {
  outline: none
}

.input input {
  border: none;
  outline: 0;
  font-size: 16px;
}


/* @media screen and (prefers-color-scheme: dark) {
  .input {
    background:  var(--primary-b99-color);
    border-color: #444;
    color: #aaa;
  }

  .container:not(.disabled) .input:hover {
    box-shadow: 0 0 0 1px var(--primary-b40-color), 0 0 12px 0 var(--primary-b80-color);
    border-color: var(--primary-b40-color);
    background: var(--primary-b90-color);
  }
  
  .draggingOver:not(.disabled) .input {
    box-shadow: 0 0 0 1px var(--primary-b40-color), 0 0 12px 0 var(--primary-b80-color);
    border-color: var(--primary-b40-color);
    background: var(--primary-b95-color);
  }


  .floatTitleContainer .title {
    color: var(--primary-w30-color);
  }
} */